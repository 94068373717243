import React from "react"
import Iframe from "react-iframe"
import { Grid, Paper, Card } from "@material-ui/core"
import Container from "@material-ui/core/Container"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"

const Support: React.FC = () => {
  return (
    <div
      style={{
        fontFamily:
          'font-family:"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h3">Plenti Support</Typography>
        </Toolbar>
      </AppBar>

      <Paper style={{ backgroundColor: "#C8E6C9", paddingBottom: 30 }}>
        <Container>
          <Grid container spacing={3} style={{ paddingTop: 30 }}>
            <Grid item md={2} />
            <Grid item md={8}>
              <Card style={{ padding: 30 }}>
                <h2 style={{ textAlign: "center" }}>How can We Help?</h2>
                <p style={{ fontSize: 20 }}>
                  Plenti is a powerful tool that we want everyone to feel
                  comfortable using.
                </p>
                <h3>Help Menu</h3>
                <p>
                  You can locate the help menu by pressing the tribar (≡) icon
                  in the top-left corner of Plenti. It has in-app text
                  instructions for how to request produce and list produce.
                </p>
                <p>
                  There is an additional help menu explaining how to update your
                  pickup address. You can find that menu on the Change Address
                  page by click the HELP button in the top-right
                </p>
                <h3>Tutorial Videos</h3>
                <p>
                  We've also made some tutorial videos you can watch for the two
                  main activities in Plenti, requesting a produce item and
                  listing a produce item. You'll find them below.
                </p>
                <h3>Support Email</h3>
                <p>
                  Finally, if you still have questions, or if you have ideas
                  about how to make Plenti better or more intuitive, you can
                  email us directly at{" "}
                  <a href="mailto: admin@plenti.dev">admin@plenti.dev</a>
                </p>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <Card style={{ height: 580, padding: 15 }}>
                <h2>Tutorial 1: Requesting Produce</h2>
                <Iframe
                  width="100%"
                  height="495"
                  url="https://www.loom.com/embed/dc9ae7f754ea4f21ad70ac0df95be104"
                  allowFullScreen
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card style={{ height: 580, padding: 15, textAlign: "center" }}>
                <h2>Tutorial 2: Listing Produce</h2>
                <Iframe
                  width="100%"
                  height="495"
                  url="https://www.loom.com/embed/67c226cfc62749eeb98e6ba7eaa70a44"
                  allowFullScreen
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper style={{ marginTop: 30 }}></Paper>
    </div>
  )
}

export default Support
