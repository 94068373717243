import React, { useContext } from "react"
import Login from "./Login"
import AccountContext from "../account/AccountContext"
import Dashboard from "./Dashboard"
import Background from "../shared/Background"

const Admin = () => {
  const { account } = useContext(AccountContext)
  return <Background>{account ? <Dashboard /> : <Login />}</Background>
}

export default Admin
