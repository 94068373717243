import { Grid, Paper, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Formik } from "formik"
import { AccountPasswordResetForm } from "plenti-api"
import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import * as Yup from "yup"
import AccountContext from "../account/AccountContext"
import Background from "../shared/Background"
import TextField from "../shared/TextField"

const PasswordReset = () => {
  const query = new URLSearchParams(useLocation().search)
  const token: string = query.get("token") || ""
  const { resetPassword } = useContext(AccountContext)
  const [serverError, setServerError] = useState<string>()
  const [success, setSuccess] = useState(false)

  const InnerComponent = (props: { handleSubmit: () => void }) => {
    if (!success) {
      return (
        <>
          <h1>Password Reset</h1>
          <TextField
            fullWidth
            name="password"
            label="New Password"
            style={{ marginBottom: 15 }}
            type="password"
          />
          <TextField
            fullWidth
            name="confirmPassword"
            label="Confirm New password"
            style={{ marginBottom: 15 }}
            type="password"
          />
          {serverError ? (
            <div>{serverError}</div>
          ) : (
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                props.handleSubmit()
              }}
            >
              Submit
            </Button>
          )}
        </>
      )
    } else {
      return (
        <>
          <h1>Success</h1>
          <Typography>
            Your password has been successfully reset. Try logging in again on
            the app now.
          </Typography>
        </>
      )
    }
  }

  return (
    <Formik
      initialValues={{ password: "", confirmPassword: "" }}
      onSubmit={(values, actions) => {
        const resetForm: AccountPasswordResetForm = {
          newPassword: values.password,
          token,
        }
        resetPassword(resetForm)
          .then(() => setSuccess(true))
          .catch((error) => {
            setServerError(
              "Invalid password reset request. Your token might have expired, try starting the process over"
            )
          })
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords don't match")
          .required("Confirm Password is required"),
      })}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (
        <Background>
          <Grid container spacing={3}>
            <Grid item sm={1} md={4}></Grid>
            <Grid item sm={10} md={4} style={{ marginTop: 40 }}>
              <Paper
                variant="outlined"
                style={{ backgroundColor: "#FFF", padding: 30 }}
              >
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <InnerComponent handleSubmit={props.handleSubmit} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item sm={1} md={4}></Grid>
          </Grid>
        </Background>
      )}
    </Formik>
  )
}

export default PasswordReset
