import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import { ClientValues, IClient } from "plenti-api/dist/src/lib/Client"
import { useContext } from "react"
import { AuthContext } from "./AuthContext"

export default class Client implements IClient {
  authToken: string = useContext(AuthContext).authToken ?? ""
  instance: AxiosInstance
  baseUrl: string
  constructor(baseUrl?: string) {
    this.instance = axios.create()
    this.baseUrl = baseUrl || ""
  }

  headers() {
    return {
      Authorization: this.authToken,
      "Content-Type": "application/json",
    }
  }

  call<T>(values: ClientValues): AxiosPromise<T> {
    const url = `${this.baseUrl}${values.endpoint}`
    console.log("calling", url)
    const config = { ...values, url, headers: this.headers() }
    return this.instance(config as AxiosRequestConfig).catch((error) => {
      console.log(`[Client gotError: <${error}> on endpoint <${url}>]`)
      throw error
    })
  }
}
