import { ThemeProvider } from "@material-ui/core"
import { createMuiTheme } from "@material-ui/core/styles"
import React from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { AccountProvider } from "./account/AccountContext"
import Admin from "./admin/Admin"
import "./App.css"
import Home from "./home/Home"
import ApiProvider from "./lib/ApiProvider"
import { AuthProvider } from "./lib/AuthProvider"
import NotMe from "./password_reset/NotMe"
import PasswordReset from "./password_reset/PasswordReset"
import Privacy from "./privacy/Privacy"
import Support from "./support/Support"

const PlentiTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#4CAF50",
    },
    // disabled: "#757575",
    secondary: { main: "#FF5252" },
    // surface: "#FFFFFF",
    // onSurface: "#212121",
    // notification: "#cea31d"
  },
})

export default function App() {
  return (
    <AuthProvider>
      <ApiProvider>
        <AccountProvider>
          <ThemeProvider theme={PlentiTheme}>
            <Router>
              <Helmet>
                <meta charSet="utf-8" />
                <link rel="icon" type="image/x-icon" href="./favicon.ico" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
                <title>Plenti</title>
                <meta
                  title="viewport"
                  content="width=device-width, initial-scale=1"
                />
                <link
                  href="http://fonts.googleapis.com/css?family=Lato:100,100i,300,300i,400,400i,700,700i,900,900i"
                  rel="stylesheet"
                  type="text/css"
                />
                <meta
                  title="description"
                  content="Plenti allows you to connect with neighbors to help them eat the extra food they&#x27;ve grown!

How it works:
- Gardeners list crops they have in excess
-"
                />
                <meta
                  title="keywords"
                  content="Plenti,Neighborhood,Cropsharing"
                />
                <meta title="og:locale" property="og:locale" content="en_US" />
                <meta title="og:type" property="og:type" content="website" />
                <meta
                  title="og:site_name"
                  property="og:site_name"
                  content="Plenti"
                />
                <meta title="og:title" property="og:title" content="Plenti" />
                <meta
                  title="og:description"
                  property="og:description"
                  content="Neighborhood Cropsharing"
                />
                <meta
                  title="og:image"
                  property="og:image"
                  content="https://s3-us-west-2.amazonaws.com/skygear-cloud-asset/appsite/3a2648b2-14d0-434b-93fe-ea04b9a80e8d-harvest_icon.png"
                />
                <meta
                  title="og:image:type"
                  property="og:image:type"
                  content="image/png"
                />
                <meta
                  title="og:image:width"
                  property="og:image:width"
                  content="1024"
                />
                <meta
                  title="og:image:height"
                  property="og:image:height"
                  content="1024"
                />
                <link rel="stylesheet" href="template/main.min.css" />
              </Helmet>
              <div>
                {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                <Switch>
                  <Route path="/admin">
                    <Admin />
                  </Route>
                  <Route path="/privacy_policy">
                    <Privacy />
                  </Route>
                  <Route path="/support">
                    <Support />
                  </Route>
                  <Route path="/accounts/passwordReset">
                    <PasswordReset />
                  </Route>
                  <Route path="/accounts/notMe">
                    <NotMe />
                  </Route>
                  <Route path="/">
                    <Home />
                  </Route>
                </Switch>
              </div>
            </Router>
          </ThemeProvider>
        </AccountProvider>
      </ApiProvider>
    </AuthProvider>
  )
}
