import {
  AccountEntity,
  AccountLoginForm,
  AccountNotMeForm,
  AccountPasswordResetForm,
} from "plenti-api"
import React, { useContext, useEffect, useState } from "react"
import ApiContext from "../lib/ApiContext"
import { AuthContext } from "../lib/AuthContext"
import LocalStorage from "../storage/LocalStorage"

export interface IAccountContext {
  account?: AccountEntity
  login: (form: AccountLoginForm) => Promise<AccountEntity>
  logout: VoidFunction
  resetPassword: (form: AccountPasswordResetForm) => Promise<AccountEntity>
  notMe: (form: AccountNotMeForm) => void
}

const AccountContext = React.createContext({} as IAccountContext)

const AccountProvider: React.FC = (props) => {
  const { accountService } = useContext(ApiContext)
  const { setAuthToken } = useContext(AuthContext)
  const [account, setAccount] = useState(LocalStorage.retrieveAccount())

  useEffect(() => {
    if (account?.authToken) {
      console.log("authToken", account.authToken)
      setAuthToken(account?.authToken)
    } else {
      setAuthToken("")
    }
  }, [account, setAuthToken])

  const login = (form: AccountLoginForm) => {
    return accountService.login(form).then((account) => {
      LocalStorage.storeAccount(account)
      setAccount(account)
      setAuthToken(account.authToken!)
      return account
    })
  }

  const logout = () => {
    LocalStorage.deleteStoredAccount()
    setAccount(undefined)
  }

  const resetPassword = (form: AccountPasswordResetForm) =>
    accountService.resetPassword(form)
  const notMe = (form: AccountNotMeForm) => accountService.notMe(form)

  const value = {
    login,
    logout,
    account,
    resetPassword,
    notMe,
  }
  return (
    <AccountContext.Provider value={value}>
      {props.children}
    </AccountContext.Provider>
  )
}

export default AccountContext
export { AccountProvider }
