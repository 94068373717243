import { LoggedInAccountEntity, LoggedInAccountModel } from "plenti-api"

const Keys = {
  Account: {
    id: "@Plenti.account.id",
    iapId: "@Plaenti.account.iapId",
    email: "@Plenti.account.email",
    username: "@Plenti.account.username",
    authToken: "@Plenti.account.authToken",
    latitude: "@Plenti.account.latitude",
    longitude: "@Plenti.account.longitude",
    pickupAddress: "@Plenti.account.pickupAddress",
    prefersMetric: "@Plenti.account.prefersMetric",
    maxDistance: "@Plenti.account.maxDistance",
  },
}

export default class LocalStorage {
  static deleteStoredAccount() {
    localStorage.clear()
  }
  static storeAccount(account: LoggedInAccountEntity) {
    let model = account.toModel()
    this.setIfNotNull(Keys.Account.id, model.id.toString())
    this.setIfNotNull(Keys.Account.iapId, model.iapId)
    this.setIfNotNull(Keys.Account.email, model.email)
    this.setIfNotNull(Keys.Account.username, model.username)
    this.setIfNotNull(Keys.Account.authToken, model.authToken)
    this.setIfNotNull(Keys.Account.latitude, model.latitude?.toString())
    this.setIfNotNull(Keys.Account.latitude, model.longitude?.toString())
    this.setIfNotNull(Keys.Account.pickupAddress, model.pickupAddress)
    this.setIfNotNull(Keys.Account.prefersMetric, `${model.prefersMetric}`)
    this.setIfNotNull(Keys.Account.maxDistance, model.maxDistance.toString())
  }

  private static setIfNotNull(key: string, value?: string) {
    if (!value) {
      return
    }
    localStorage.setItem(key, value)
  }

  static retrieveAuthToken(): string | undefined {
    return localStorage.getItem(Keys.Account.authToken) ?? undefined
  }

  static retrieveAccount(): LoggedInAccountEntity | undefined {
    const id = localStorage.getItem(Keys.Account.id)
    const iapId = localStorage.getItem(Keys.Account.iapId)
    const email = localStorage.getItem(Keys.Account.email)
    const username = localStorage.getItem(Keys.Account.username)
    const authToken = localStorage.getItem(Keys.Account.authToken)
    const latitude = localStorage.getItem(Keys.Account.latitude) ?? undefined
    const longitude = localStorage.getItem(Keys.Account.longitude) ?? undefined
    const pickupAddress =
      localStorage.getItem(Keys.Account.pickupAddress) ?? undefined
    const prefersMetricStr = localStorage.getItem(Keys.Account.prefersMetric)
    const prefersMetric = prefersMetricStr === "true"
    const maxDistance = parseInt(
      localStorage.getItem(Keys.Account.maxDistance) || "0"
    )

    if (!id || !email || !username || !authToken || !iapId) {
      return undefined
    }

    const numId = parseInt(id)
    const numlat = parseFloat(latitude ?? "0")
    const numlng = parseFloat(longitude ?? "")

    let model: LoggedInAccountModel = {
      id: numId,
      iapId,
      email,
      username,
      authToken,
      latitude: numlat,
      longitude: numlng,
      pickupAddress,
      prefersMetric,
      maxDistance,
      reports: [],
    }

    return new LoggedInAccountEntity(model)
  }
}
