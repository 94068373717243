import React, { useState, useContext } from "react"
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import InboxIcon from "@material-ui/icons/MoveToInbox"
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen"
import MailIcon from "@material-ui/icons/Mail"

import PushNotifications from "./push_notifications/PushNotifications"
import Placeholder from "./Placeholder"
import AccountContext from "../account/AccountContext"

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },

    content: {
      flexGrow: 1,
      padding: theme.spacing(3)
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar
  })
)

type DashboardSelection = "PushNotifications" | "None"

const Dashboard = () => {
  const classes = useStyles()
  const [selected, setSelected] = useState<DashboardSelection>(
    "PushNotifications"
  )
  const { logout } = useContext(AccountContext)

  const SelectedComponent = () => {
    switch (selected) {
      case "PushNotifications":
        return <PushNotifications />
      case "None":
        return <Placeholder />
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap style={{ flexGrow: 1 }}>
            Dashboard
          </Typography>
          <Button onClick={logout}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.toolbar} />
        <List>
          <ListItem
            button
            key="Push Notifications"
            onClick={() => setSelected("PushNotifications")}
          >
            <ListItemIcon>
              <AddToHomeScreenIcon />
            </ListItemIcon>
            <ListItemText primary="Push Notifications" />
          </ListItem>
        </List>
        <Divider />
        <List>
          {["All mail", "Trash", "Spam"].map((text, index) => (
            <ListItem button key={text} onClick={() => setSelected("None")}>
              <ListItemIcon>
                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <SelectedComponent />
      </main>
    </div>
  )
}

export default Dashboard
