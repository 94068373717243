import React, { useContext } from "react"
import TextInput from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import PushNotificationContext from "./PushNotificationContext"

const MessageInput = () => {
  const { message, setMessage, sendPushes } = useContext(
    PushNotificationContext
  )

  const handleOnChange = (event: any) => {
    setMessage(event.target.value)
  }

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        padding: 15
      }}
    >
      <TextInput
        style={{ width: "79%" }}
        variant="outlined"
        value={message}
        onChange={handleOnChange}
      />
      <Button
        style={{ width: "20%", height: 55, marginLeft: "1%" }}
        variant="contained"
        color="primary"
        onClick={sendPushes}
      >
        Send Message
      </Button>
    </div>
  )
}

export default MessageInput
