import React from "react"
import DeviceTable from "./DeviceTable"
import { PushNotificationProvider } from "./PushNotificationContext"
import MessageInput from "./MessageInput"

const PushNotifications = () => {
  return (
    <>
      <PushNotificationProvider>
        <DeviceTable />
        <MessageInput />
      </PushNotificationProvider>
    </>
  )
}

export default PushNotifications
