import React, { useContext } from "react"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import Checkbox from "@material-ui/core/Checkbox"
import PushNotificationContext from "./PushNotificationContext"

interface Props {
  rowCount: number
  headerLabels: string[]
}

const DeviceTableHeadRow: React.FC<Props> = props => {
  const { selectedDevices, devices, setSelectedDevices } = useContext(
    PushNotificationContext
  )
  const { rowCount, headerLabels } = props

  const numSelected = selectedDevices.length

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedDevices(devices)
      return
    }
    setSelectedDevices([])
  }

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headerLabels.map((label, index) => (
          <TableCell key={label} align={index === 0 ? "left" : "right"}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

export default DeviceTableHeadRow
