import React, { useContext } from "react"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"

import {
  createStyles,
  lighten,
  makeStyles,
  Theme
} from "@material-ui/core/styles"
import PushNotificationContext from "./PushNotificationContext"

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85)
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark
          },
    title: {
      flex: "1 1 100%"
    }
  })
)

const DeviceTableToolbar = () => {
  const { selectedDevices } = useContext(PushNotificationContext)
  const classes = useToolbarStyles()
  const numSelected = selectedDevices.length

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Devices
        </Typography>
      )}
    </Toolbar>
  )
}

export default DeviceTableToolbar
