import Checkbox from "@material-ui/core/Checkbox"
import TableCell from "@material-ui/core/TableCell"
import TableRow from "@material-ui/core/TableRow"
import moment from "moment"
import { DeviceEntity } from "plenti-api"
import React, { useContext } from "react"
import PushNotificationContext from "./PushNotificationContext"

interface Props {
  device: DeviceEntity
}

interface DeviceData {
  deviceIdentifier: string
  lastSeen: string
  email?: string
  deviceType?: string
}

const DeviceTableRow: React.FC<Props> = (props) => {
  const { device } = props
  const { isSelected, select, deselect } = useContext(PushNotificationContext)
  const iAmSelected = isSelected(device)
  const data: DeviceData = {
    deviceIdentifier: device.deviceIdentifier,
    lastSeen: moment(device.lastSeen).format("MMMM Do YYYY"),
    email: device.account?.email,
    deviceType: device.deviceType,
  }

  const handleClick = () => {
    console.log("clicky")
    if (iAmSelected) {
      deselect(device)
    } else {
      select(device)
    }
  }

  return (
    <TableRow
      hover
      onClick={handleClick}
      role="checkbox"
      aria-checked={iAmSelected}
      tabIndex={-1}
      selected={iAmSelected}
    >
      <TableCell padding="checkbox">
        <Checkbox checked={iAmSelected} />
      </TableCell>
      <TableCell component="th" scope="row" padding="none">
        {data.deviceIdentifier}
      </TableCell>
      <TableCell align="right">{data.lastSeen}</TableCell>
      <TableCell align="right">{data.email}</TableCell>
      <TableCell align="right">{data.deviceType}</TableCell>
    </TableRow>
  )
}

export default DeviceTableRow
