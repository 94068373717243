import { DeviceEntity, DeviceSendPushesForm } from "plenti-api"
import React, { useCallback, useContext, useEffect, useState } from "react"
import ApiContext from "../../lib/ApiContext"

export interface IPushNotificationContext {
  devices: DeviceEntity[]
  selectedDevices: DeviceEntity[]
  setSelectedDevices: (devices: DeviceEntity[]) => void
  isSelected: (device: DeviceEntity) => boolean
  select: (device: DeviceEntity) => void
  deselect: (device: DeviceEntity) => void
  message: string
  setMessage: (message: string) => void
  sendPushes: () => void
}

const PushNotificationContext = React.createContext(
  {} as IPushNotificationContext
)

const PushNotificationProvider: React.FC = (props) => {
  const { deviceService } = useContext(ApiContext)
  const [devices, setDevices] = useState<DeviceEntity[]>([])
  const [message, setMessage] = useState("")
  const [selectedDevices, setSelectedDevices] = useState<DeviceEntity[]>([])

  const refresh = useCallback(() => {
    deviceService.getAll().then((devices) => {
      setDevices(devices)
    })
  }, [deviceService])

  const isSelected = (device: DeviceEntity) => {
    return selectedDevices.indexOf(device) > -1
  }

  const select = (device: DeviceEntity) => {
    setSelectedDevices([...selectedDevices, device])
  }

  const deselect = (device: DeviceEntity) => {
    const index = selectedDevices.indexOf(device)
    if (index > -1) {
      let mutableCopy = [...selectedDevices]
      mutableCopy.splice(index, 1)
      setSelectedDevices(mutableCopy)
    }
  }

  const sendPushes = () => {
    const ids = selectedDevices.map((d) => d.id)
    const pushesForm: DeviceSendPushesForm = {
      deviceIds: ids,
      message,
    }
    deviceService.sendPushes(pushesForm)
  }

  useEffect(() => {
    refresh()
  }, [refresh])

  const value: IPushNotificationContext = {
    devices,
    selectedDevices,
    isSelected,
    setSelectedDevices,
    select,
    deselect,
    message,
    setMessage,
    sendPushes,
  }

  return (
    <PushNotificationContext.Provider value={value}>
      {props.children}
    </PushNotificationContext.Provider>
  )
}

export default PushNotificationContext
export { PushNotificationProvider }
