import { Grid, Paper, Typography } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { AccountNotMeForm } from "plenti-api"
import React, { useContext, useState } from "react"
import { useLocation } from "react-router-dom"
import AccountContext from "../account/AccountContext"
import Background from "../shared/Background"

const NotMe = () => {
  const [buttonClicked, setButtonClicked] = useState(false)
  const query = new URLSearchParams(useLocation().search)
  const { notMe } = useContext(AccountContext)
  const token: string = query.get("token") || ""

  const onSubmit = () => {
    const form: AccountNotMeForm = {
      token,
    }
    notMe(form)
    setButtonClicked(true)
  }

  return (
    <Background>
      <Grid container spacing={3}>
        <Grid item sm={1} md={4}></Grid>
        <Grid item sm={10} md={4} style={{ marginTop: 40 }}>
          <Paper
            variant="outlined"
            style={{ backgroundColor: "#FFF", padding: 30 }}
          >
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1>Caution</h1>
                <Typography style={{ paddingBottom: 15 }}>
                  Click the button below if you are sure that you did not
                  request a password reset. Clicking the button below will mark
                  the account associated with this email as malicious.
                </Typography>
                <Typography style={{ paddingBottom: 15 }}>
                  email{" "}
                  <a
                    href="mailto:admin@plenti.dev?Subject=Malicious Account"
                    target="_top"
                  >
                    admin@plenti.dev
                  </a>{" "}
                  to get it reinstated.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {buttonClicked ? (
                  <Typography style={{ textAlign: "center" }}>
                    Submited
                  </Typography>
                ) : (
                  <Button fullWidth variant="contained" onClick={onSubmit}>
                    Mark Account Malicious
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={1} md={4}></Grid>
      </Grid>
    </Background>
  )
}

export default NotMe
