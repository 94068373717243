import React from "react"
import { Grid, Paper, Card } from "@material-ui/core"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import appIcon from "./appicon.png"
import plentiHome from "./plenti_home.png"
import plentiSplash from "./plenti_splash.png"
import plentiChat from "./plenti_chat.png"
import appStoreBadge from "./AppStoreDownloadBadge.svg"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}))

const Home = () => {
  const environmentDescriptor = process.env.REACT_APP_ENV_DISPLAY_NAME
  const title = `Plenti ${environmentDescriptor}`
  const classes = useStyles()
  return (
    <div
      style={{
        fontFamily:
          'font-family:"Helvetica Neue", Helvetica, Roboto, Arial, sans-serif',
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Paper style={{ backgroundColor: "#C8E6C9", paddingBottom: 30 }}>
        <Container>
          <Grid container spacing={3} style={{ paddingTop: 30 }}>
            <Grid item xs={12} md={6} style={{ textAlign: "center" }}>
              <img
                style={{
                  borderRadius: 100,
                  maxWidth: "100%",
                  maxHeight: "100%",
                }}
                src={appIcon}
                alt=""
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Card style={{ height: "95%", padding: 15 }}>
                <h2>Neighborhood Cropsharing</h2>
                <p>
                  Plenti allows you to connect with neighbors to help them eat
                  the extra food they've grown!
                </p>
                <p>How it works:</p>
                <ul>
                  <li>Gardeners list crops they have in excess</li>
                  <li>Users request produce</li>
                  <li>
                    If there are gardeners in your area that have too much, you
                    are able to chat
                  </li>
                  <li>
                    Pick up produce at a location specified by the Gardener
                  </li>
                </ul>
                <h2>Ready to give it a try?</h2>
                <a href="https://play.google.com/store/apps/details?id=dev.plenti.plenti&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                  <img
                    style={{ maxWidth: 150 }}
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
                <a href="https://apps.apple.com/us/app/plenti-cropshare/id1455448690">
                  <img
                    style={{ maxWidth: 200 }}
                    alt="Get it on App Store"
                    src={appStoreBadge}
                  />
                </a>

                <ul style={{ listStyleType: "none", paddingLeft: "none" }}>
                  <li style={{ display: "inline" }}>
                    <Link to="/privacy_policy">Privacy Policy</Link>
                  </li>
                  <li style={{ display: "inline", marginLeft: 15 }}>
                    <Link to="/support">Support</Link>
                  </li>
                  <li style={{ display: "inline", marginLeft: 15 }}>
                    <a href="mailto: admin@plenti.dev">Contact Us</a>
                  </li>
                </ul>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Paper>
      <Paper style={{ marginTop: 30 }}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={plentiHome}
                alt=""
              />
              <h3 style={{ textAlign: "center" }}>Find fresh produce</h3>
              <p>
                Use Plenti to locate produce near you. If you don't see what
                you're looking for, just let us know and we'll add it to the
                app.
              </p>
            </Grid>
            <Grid item xs={4}>
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={plentiSplash}
                alt=""
              />
            </Grid>
            <Grid item xs={4}>
              <img
                style={{
                  maxWidth: "100%",
                }}
                src={plentiChat}
                alt=""
              />
              <h3 style={{ textAlign: "center" }}>Share with your neighbors</h3>
              <p>
                If one of your neighbors has what you're looking for, you'll be
                connected and can chat.
              </p>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </div>
  )
}

export default Home
