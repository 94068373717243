import React, { useState } from "react"
import LocalStorage from "../storage/LocalStorage"
import { AuthContext } from "./AuthContext"

export const AuthProvider: React.FC = (props) => {
  const [authToken, setAuthToken] = useState(LocalStorage.retrieveAuthToken())
  const value = { authToken, setAuthToken }

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  )
}
