import React, { useContext, useState } from "react"
import { Grid, Paper } from "@material-ui/core"
import { Formik } from "formik"
import TextField from "../shared/TextField"
import Button from "@material-ui/core/Button"
import AccountContext from "../account/AccountContext"
import { AccountLoginValidator } from "plenti-api"

const Login = () => {
  const { login } = useContext(AccountContext)
  const [serverError, setServerError] = useState<string | undefined>(undefined)
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values, actions) => {
        login(values).catch((error) => {
          setServerError("Something went wrong. Check credentials.")
        })
      }}
      validationSchema={AccountLoginValidator}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => (
        <Grid container spacing={3}>
          <Grid item sm={1} md={4}></Grid>
          <Grid item sm={10} md={4} style={{ marginTop: 40 }}>
            <Paper
              variant="outlined"
              style={{ backgroundColor: "#FFF", padding: 30 }}
            >
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <h1>Admin Login</h1>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Email"
                    style={{ marginBottom: 15 }}
                    type="email"
                    serverError={serverError}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="password"
                    label="Password"
                    style={{ marginBottom: 15 }}
                    type="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => {
                      props.handleSubmit()
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item sm={1} md={4}></Grid>
        </Grid>
      )}
    </Formik>
  )
}

export default Login
