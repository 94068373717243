import {
  AccountService,
  CatalogRequestService,
  ConversationService,
  DeviceService,
  InventoryService,
  PlentiItemService,
  PlentiRequestService,
} from "plenti-api"
import React from "react"
import ApiContext from "./ApiContext"
import Client from "./Client"

const ApiProvider: React.FC = (props) => {
  const client = new Client(process.env.REACT_APP_BASE_URL)

  const value = {
    accountService: new AccountService(client),
    catalogRequestService: new CatalogRequestService(client),
    conversationService: new ConversationService(client),
    inventoryService: new InventoryService(client),
    plentiItemService: new PlentiItemService(client),
    plentiRequestService: new PlentiRequestService(client),
    deviceService: new DeviceService(client),
  }

  return (
    <ApiContext.Provider value={value}>{props.children}</ApiContext.Provider>
  )
}

export default ApiProvider
